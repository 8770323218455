import { Fragment } from 'react';
import clsx from 'clsx';
import { Menu, Transition } from '@headlessui/react';
import { ArrowRight, OpenBook } from 'iconoir-react';
import { useProductCategories } from '@lib/hooks/use-product-category';
import { useCatalogTranslation } from '../../../../lib/hooks/use-catalog-translations';
import { useRouter } from 'next/router';
import CategoryCard from '../../components/category-cards/category-card';

const CatalogMenu = () => {
  const { ct } = useCatalogTranslation('common');
  const router = useRouter();
  const fields = 'id,name,thumbnail,handle';
  const parent_category_id = 'null';
  const expand = '';

  const { categories } = useProductCategories({
    fields,
    parent_category_id,
    expand,
  });

  const handleSearchAllCategories = () => {
    router.push('/products');
  };

  const handleSearchCategory = ({ handle }: { handle: string }) => {
    const path = `/products?handle=${handle}`;
    router.push(path);
  };

  return (
    <Menu as="div" className="relative hidden  md:inline-block text-left">
      {({ open, close }) => (
        <>
          <div>
            <Menu.Button
              data-testid="category-menu"
              className={clsx(
                'inline-flex w-full justify-center items-center gap-2 rounded-md px-3 py-2 text-sm leading-5 text-grey2-60 hover:bg-grey2-5',
                open ? 'bg-grey2-5' : 'bg-white',
              )}
            >
              <OpenBook className="w-4 h-4" />
              <span className="text-base leading-5">
                {ct('CommonNavbar.categories', 'Catégories')}
              </span>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className="absolute left-0 z-10 p-4 mt-2 w-[600px] origin-top-right max-h-[464px] overflow-auto scrollbar-hide rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              data-testid="category-dropdown"
            >
              <div className="text-base flex items-center justify-between w-full font-semibold leading-6 mb-4">
                <h3 className="text-black">
                  {ct('CommonNavbar.myCategories', 'Nos catégories')}
                </h3>
                <button
                  className="flex items-center gap-2"
                  onClick={() => {
                    close();
                    handleSearchAllCategories();
                  }}
                >
                  <ArrowRight className="text-seller" strokeWidth="1.5px" />
                  <p className="text-seller text-base">
                    {ct('CommonCta.seeAll')}
                  </p>
                </button>
              </div>
              <div className="grid grid-cols-3 gap-x-8 gap-y-6 place-items-stretch">
                {categories &&
                  categories.map((c) => (
                    <div key={c.id}>
                      <CategoryCard
                        categoryId={c.id}
                        categoryHandle={c.handle}
                        categoryTitle={c.name}
                        size="sm"
                        img={c.thumbnail}
                        cardClicked={({ handle }) => {
                          close();

                          return handleSearchCategory({ handle });
                        }}
                      />
                    </div>
                  ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default CatalogMenu;
