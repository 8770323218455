import { BoxIso, Menu, OpenBook, Star } from 'iconoir-react';
import { useRouter } from 'next/router';
import React, { Fragment, useState } from 'react';
import { useCatalogTranslation } from '../../../../lib/hooks/use-catalog-translations';
import CartPopover from '../../components/cart-popover';
import CatalogMenu from './catalog-menu';
import LanguageDropdown from './language-dropdown';
import Logo from './logo';
import MobileDropdownMenu from './mobile-dropdown-menu';
import MoreDropdown from './more-dropdown';
import { useSettings } from '@lib/hooks/use-settings';

function CatalogNav(): JSX.Element {
  const { ct } = useCatalogTranslation('common');
  const router = useRouter();
  const iconClasses = 'w-4 h-4';
  const [open, setOpen] = useState(false);
  const { displayCategories } = useSettings();

  const menuItems = [
    {
      label: ct('CommonNavbar.orders'),
      path: '/orders',
      icon: <BoxIso className={iconClasses} />,
    },
    {
      label: ct('CommonNavbar.favorites'),
      path: '/favorite-lists',
      icon: <Star className={iconClasses} />,
    },
  ];
  return (
    <>
      <div
        className={
          'relative z-10 flex h-16 items-center justify-between border-b border-grey2-10 bg-white px-4 text-sm text-grey2-60 sm:mx-4 sm:border-x sm:rounded-b-xl'
        }
      >
        <div className="flex items-center gap-4">
          <button
            data-testid="burger"
            className="flex md:hidden h-8 w-8 items-center justify-center gap-2 rounded-full border border-grey2-20 p-1 shadow-xs text-grey2-60"
            onClick={() => setOpen(!open)}
          >
            <Menu className="h-4 w-4 stroke-2" />
          </button>
          <Logo />
          <div className="hidden md:block h-[20px] w-[1px] bg-grey2-10"></div>
          {
            //if displayCategories is true, display the CatalogMenu component otherwise, display a link to /products
            displayCategories ? (
              <CatalogMenu />
            ) : (
              <div
                className="flex items-center h-10 gap-2 cursor-pointer hover:text-grey2-90"
                onClick={() => {
                  router.push('/products');
                }}
              >
                <OpenBook className="w-4 h-4" />
                {ct('CommonNavbar.catalog', 'Catalogue')}
              </div>
            )
          }
        </div>
        <div className="flex items-center gap-4">
          <div className="hidden md:flex items-center gap-4">
            {menuItems?.map((item) => (
              <Fragment key={item.label}>
                <div
                  className="flex items-center h-10 px-4 gap-2 cursor-pointer hover:text-grey2-90"
                  onClick={() => {
                    router.push(item.path);
                  }}
                >
                  {item.icon}
                  <span className="text-base leading-5">{item.label}</span>
                </div>
                <div className="h-[20px] w-[1px] bg-grey2-10"></div>
              </Fragment>
            ))}
          </div>
          <MoreDropdown />
          <div className="hidden md:block h-[20px] w-[1px] bg-grey2-10"></div>
          <LanguageDropdown />
          <div className="hidden md:block h-[20px]  w-[1px] bg-grey2-10"></div>
          <CartPopover displayButton={true} />
        </div>
      </div>
      <div className="relative">
        <MobileDropdownMenu open={open} setOpen={setOpen} />
      </div>
    </>
  );
}

export default CatalogNav;
